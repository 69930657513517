import { Button } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { createOrbitControls, SkinViewer } from 'skinview3d'

import download from './helpers/downloadHandler'
import useMinecraftSkinEditor from './hooks/useMinecraftSkinEditor'
import { SkinTab } from './helpers/constants'
import SkinPicker from './components/SkinItems'
import SkinNavigator from './components/SkinNavigator'
import FlatSkinViewer from './components/FlatSkinViewer'
import { useWindowSize } from 'react-use'

const App = () => {
  const skinEditor = useMinecraftSkinEditor()
  const { width } = useWindowSize()
  const ref: any = useRef(null)

  const handleExport = () => {
    const uri = ref.current.toDataURL()
    download(uri)
  }

  const [selectedTab, setSelectedTab] = useState<SkinTab>(0)
  const [skinViewer, setSkinViewer] = useState<SkinViewer>(null)

  useEffect(() => {
    const uri = ref.current.toDataURL()

    const isLg = width > 1280

    if (!skinViewer) {
      let skinViewer = new SkinViewer({
        canvas: document.getElementById('skin_container') as HTMLCanvasElement,
        width: isLg ? 400 : 200,
        height: isLg ? 400 : 200,
        skin: uri,
        fov: 80,
      })

      let orbitControl = createOrbitControls(skinViewer)

      orbitControl.enableRotate = true
      setSkinViewer(skinViewer)
    } else {
      skinViewer.width = isLg ? 400 : 200
      skinViewer.height = isLg ? 400 : 200
      skinViewer.loadSkin(uri)
    }
  }, [skinEditor]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex gap-5 flex-col-reverse xl:flex-row xl:max-w-none justify-center h-full py-10 max-w-lg mx-auto">
      <div>
        <SkinNavigator
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="scrollbar-hidden py-10 overflow-y-scroll">
          <SkinPicker selectedTab={selectedTab} skinEditor={skinEditor} />
        </div>
      </div>
      <div className="w-full max-w-3xl">
        <div className="border-2 p-10 w-full flex gap-10 justify-between h-max rounded-sm">
          <FlatSkinViewer skinEditor={skinEditor} innerRef={ref} />
          <canvas id="skin_container"></canvas>
        </div>
        <div className="flex items-center gap-2">
          <Button onClick={handleExport} variant="outline" className="mt-5">
            Download
          </Button>
          <Button
            onClick={skinEditor.randomSkin}
            variant="outline"
            className="mt-5"
          >
            Random
          </Button>
          <Button
            onClick={skinEditor.resetSkin}
            color="red"
            variant="outline"
            className="mt-5"
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  )
}

export default App
