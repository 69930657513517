import assets from "../assets.json"

const useAssets = () => {
  const filter = (name: string) => (item: string) => item.includes(name)

  const skins = assets.items.filter(filter("1-skin"))
  const pants = assets.items.filter(filter("2-pants"))
  const shoes = assets.items.filter(filter("3-shoes"))
  const shirts = assets.items.filter(filter("4-shirt"))
  const jackets = assets.items.filter(filter("5-jacket"))
  const eyes = assets.items.filter(filter("6-eyes"))
  const mouths = assets.items.filter(filter("7-mouth"))
  const glasses = assets.items.filter(filter("8-glasses"))
  const hairs = assets.items.filter(filter("9-hair"))
  const hats = assets.items.filter(filter("10-hats"))

  return {
    skins,
    pants,
    shoes,
    shirts,
    jackets,
    eyes,
    mouths,
    glasses,
    hairs,
    hats,
  }
}

export default useAssets
