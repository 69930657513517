import { useEffect } from 'react'
import { preloadImages } from '../helpers/preload-images'
import SkinList from './SkinList'
import assets from '../assets.json'

const SkinPicker = ({ skinEditor, selectedTab }) => {
  useEffect(() => {
    preloadImages(assets.items)
  }, [])

  return (
    <>
      {selectedTab === 0 ? (
        <SkinList dir="1-skins" title="Skins" setItem={skinEditor.setSkin} />
      ) : selectedTab === 1 ? (
        <SkinList dir="2-pants" title="Pants" setItem={skinEditor.setPant} />
      ) : selectedTab === 2 ? (
        <SkinList dir="3-shoes" title="Shoes" setItem={skinEditor.setShoes} />
      ) : selectedTab === 3 ? (
        <SkinList dir="4-shirts" title="Shirts" setItem={skinEditor.setShirt} />
      ) : selectedTab === 4 ? (
        <SkinList
          dir="5-jackets"
          title="Jackets"
          setItem={skinEditor.setJckt}
        />
      ) : selectedTab === 5 ? (
        <SkinList dir="6-eyes" title="Eyes" setItem={skinEditor.setEye} />
      ) : selectedTab === 6 ? (
        <SkinList dir="7-mouth" title="Mouths" setItem={skinEditor.setMouth} />
      ) : selectedTab === 7 ? (
        <SkinList
          dir="8-glasses"
          title="Glasses"
          setItem={skinEditor.setGlass}
        />
      ) : selectedTab === 8 ? (
        <SkinList dir="9-hair" title="Hairs" setItem={skinEditor.setHair} />
      ) : (
        selectedTab === 9 && (
          <SkinList dir="10-hats" title="Hats" setItem={skinEditor.setHat} />
        )
      )}
    </>
  )
}

export default SkinPicker
