export const tabs = [
  "skins",
  "pants",
  "shoes",
  "shirts",
  "jackets",
  "eyes",
  "mouths",
  "glasses",
  "hairs",
  "hats",
]

export enum SkinTab {
  SKINS,
  PANTS,
  SHOES,
  SHIRTS,
  JACKETS,
  EYES,
  MOUTHS,
  GLASSES,
  HAIRS,
  HATS,
}
