import { useRef, useState } from "react"
import useImage from "use-image"

const useCanvoImage = () => {
  const [link, setLink] = useState("")
  const [image] = useImage(link)
  const ref = useRef(null)

  return {
    setLink,
    image,
    ref,
    link,
  }
}

export default useCanvoImage
