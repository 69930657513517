export const preloadImages = (urlList: string[]) => {
  let fragment = document.createDocumentFragment()
  for (let i = 0; i < urlList.length; i++) {
    const imgUrl = urlList[i]
    const linkEl = document.createElement("link")
    linkEl.setAttribute("rel", "preload")
    linkEl.setAttribute("href", imgUrl)
    linkEl.setAttribute("as", "image")
    fragment.appendChild(linkEl)
  }
  document.head.appendChild(fragment)
}
