import { FC, memo } from 'react'
import { Tooltip } from '@mantine/core'
import assets from '../assets.json'

interface ISkinsProps {
  setItem: (str: string) => void
  title: string
  dir: string
}

const SkinList: FC<ISkinsProps> = ({ setItem, title, dir }) => {
  const filter = (name: string) => (item: string) => item.includes(name)
  const items = assets.items.filter(filter(dir))

  return (
    <div>
      <h2 className="text-2xl font-bold -mb-5 mt-1">{title}</h2>
      <div className="max-w-6xl mx-auto grid grid-cols-3 sm:grid-cols-4 gap-2 mt-10 select-none">
        <div
          className="p-5 border text-center skin-item font-bold cursor-pointer hover:bg-gray-200"
          onClick={() => setItem('')}
        />
        {items.map((link, _idx) => {
          const skinName = link.split('/').pop().replace(/.png/i, '')
          return (
            <Tooltip
              wrapLines
              withArrow
              transition="fade"
              transitionDuration={200}
              label={skinName}
              key={link}
            >
              <div
                className="p-5 border skin-item cursor-pointer hover:bg-gray-200"
                onClick={() => setItem(link)}
              >
                <img alt={link} src={link} />
              </div>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}

export default memo(SkinList)
