import { Image, Layer, Stage } from 'react-konva'

const FlatSkinViewer = ({ skinEditor, innerRef }) => {
  return (
    <Stage className="self-center" width={64} height={64} ref={innerRef}>
      <Layer>
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.skinRef}
          image={skinEditor.skinImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.pantRef}
          image={skinEditor.pantImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.shoesRef}
          image={skinEditor.shoesImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.shirtRef}
          image={skinEditor.shirtImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.jcktRef}
          image={skinEditor.jcktImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.eyeRef}
          image={skinEditor.eyeImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.mouthRef}
          image={skinEditor.mouthImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.glassRef}
          image={skinEditor.glassImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.hairRef}
          image={skinEditor.hairImg}
        />
        <Image
          fillEnabled
          height={64}
          width={64}
          ref={skinEditor.hatRef}
          image={skinEditor.hatImg}
        />
      </Layer>
    </Stage>
  )
}

export default FlatSkinViewer
