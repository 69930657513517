import { tabs } from '../helpers/constants'

const SkinNavigator = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="grid gap-4 grid-cols-5 sm:grid-cols-6">
      {tabs.map((tab, _idx) => (
        <p
          className={`cursor-pointer capitalize text-gray-600 ${
            selectedTab === _idx ? 'text-gray-100' : ''
          }`}
          key={tab}
          onClick={() => setSelectedTab(_idx)}
        >
          {tab}
        </p>
      ))}
    </div>
  )
}

export default SkinNavigator
