import { useEffect } from "react"
import useAssets from "../helpers/useAssets"
import useCanvoImage from "./useCanvoImage"

const useMinecraftSkinEditor = () => {
  const {
    image: hairImg,
    setLink: setHair,
    ref: hairRef,
    link: hairLink,
  } = useCanvoImage()
  const {
    image: skinImg,
    setLink: setSkin,
    ref: skinRef,
    link: skinLink,
  } = useCanvoImage()
  const {
    image: pantImg,
    setLink: setPant,
    ref: pantRef,
    link: pantLink,
  } = useCanvoImage()
  const {
    image: eyeImg,
    setLink: setEye,
    ref: eyeRef,
    link: eyeLink,
  } = useCanvoImage()
  const {
    image: glassImg,
    setLink: setGlass,
    ref: glassRef,
    link: glassLink,
  } = useCanvoImage()
  const {
    image: shirtImg,
    setLink: setShirt,
    ref: shirtRef,
    link: shirtLink,
  } = useCanvoImage()
  const {
    image: hatImg,
    setLink: setHat,
    ref: hatRef,
    link: hatLink,
  } = useCanvoImage()
  const {
    image: mouthImg,
    setLink: setMouth,
    ref: mouthRef,
    link: mouthLink,
  } = useCanvoImage()
  const {
    image: shoesImg,
    setLink: setShoes,
    ref: shoesRef,
    link: shoesLink,
  } = useCanvoImage()
  const {
    image: jcktImg,
    setLink: setJckt,
    ref: jcktRef,
    link: jcktLink,
  } = useCanvoImage()

  const {
    eyes,
    glasses,
    hairs,
    hats,
    jackets,
    mouths,
    pants,
    shirts,
    shoes,
    skins,
  } = useAssets()

  const randomSkin = () => {
    setEye(eyes[Math.floor(Math.random() * eyes.length)])
    setGlass(glasses[Math.floor(Math.random() * glasses.length)])
    setHair(hairs[Math.floor(Math.random() * hairs.length)])
    setHat(hats[Math.floor(Math.random() * hats.length)])
    setMouth(mouths[Math.floor(Math.random() * mouths.length)])
    setPant(pants[Math.floor(Math.random() * pants.length)])
    setShirt(shirts[Math.floor(Math.random() * shirts.length)])
    setShoes(shoes[Math.floor(Math.random() * shoes.length)])
    setSkin(skins[Math.floor(Math.random() * skins.length)])
    setJckt(jackets[Math.floor(Math.random() * jackets.length)])
  }

  const resetSkin = () => {
    setEye("")
    setGlass("")
    setHair("")
    setHat("")
    setMouth("")
    setPant("")
    setShirt("")
    setShoes("")
    setSkin("")
    setJckt("")
  }

  useEffect(randomSkin, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    hairImg,
    setHair,
    hairRef,
    hairLink,
    skinImg,
    setSkin,
    skinRef,
    skinLink,
    pantImg,
    setPant,
    pantRef,
    pantLink,
    eyeImg,
    setEye,
    eyeRef,
    eyeLink,
    glassImg,
    setGlass,
    glassRef,
    glassLink,
    shirtImg,
    setShirt,
    shirtRef,
    shirtLink,
    hatImg,
    setHat,
    hatRef,
    hatLink,
    mouthImg,
    setMouth,
    mouthRef,
    mouthLink,
    shoesImg,
    setShoes,
    shoesRef,
    shoesLink,
    jcktImg,
    setJckt,
    jcktRef,
    jcktLink,
    resetSkin,
    randomSkin,
  }
}

export default useMinecraftSkinEditor
